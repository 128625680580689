import React from "react";
import { Result, Button } from "antd";
import { Link } from "gatsby";

function IscrizioniChiuse({ location }) {
  return (
    <Result
      style={{ marginTop: 40 }}
      status="403"
      title="Iscrizioni chiuse"
      subTitle="Ci dispiace ma non è possibile iscriversi in questo momento"
      extra={
        <Link to="/">
          <Button type="primary">Torna alla homepage</Button>
        </Link>
      }
    />
  );
}

export default IscrizioniChiuse;
